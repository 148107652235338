/**
 *  AAC helper
 */

class AAC {
  static getSilentFrame(
    codec?: string,
    channelCount?: number
  ): Uint8Array | undefined {
    switch (codec) {
      case 'mp4a.40.2':
        if (channelCount === 1) {
          return new Uint8Array([0x00, 0xc8, 0x00, 0x80, 0x23, 0x80]);
        } else if (channelCount === 2) {
          return new Uint8Array([
            0x21,
            0x00,
            0x49,
            0x90,
            0x02,
            0x19,
            0x00,
            0x23,
            0x80,
          ]);
        } else if (channelCount === 3) {
          return new Uint8Array([
            0x00,
            0xc8,
            0x00,
            0x80,
            0x20,
            0x84,
            0x01,
            0x26,
            0x40,
            0x08,
            0x64,
            0x00,
            0x8e,
          ]);
        } else if (channelCount === 4) {
          return new Uint8Array([
            0x00,
            0xc8,
            0x00,
            0x80,
            0x20,
            0x84,
            0x01,
            0x26,
            0x40,
            0x08,
            0x64,
            0x00,
            0x80,
            0x2c,
            0x80,
            0x08,
            0x02,
            0x38,
          ]);
        } else if (channelCount === 5) {
          return new Uint8Array([
            0x00,
            0xc8,
            0x00,
            0x80,
            0x20,
            0x84,
            0x01,
            0x26,
            0x40,
            0x08,
            0x64,
            0x00,
            0x82,
            0x30,
            0x04,
            0x99,
            0x00,
            0x21,
            0x90,
            0x02,
            0x38,
          ]);
        } else if (channelCount === 6) {
          return new Uint8Array([
            0x00,
            0xc8,
            0x00,
            0x80,
            0x20,
            0x84,
            0x01,
            0x26,
            0x40,
            0x08,
            0x64,
            0x00,
            0x82,
            0x30,
            0x04,
            0x99,
            0x00,
            0x21,
            0x90,
            0x02,
            0x00,
            0xb2,
            0x00,
            0x20,
            0x08,
            0xe0,
          ]);
        }

        break;
      // handle HE-AAC below (mp4a.40.5 / mp4a.40.29)
      default:
        if (channelCount === 1) {
          // ffmpeg -y -f lavfi -i "aevalsrc=0:d=0.05" -c:a libfdk_aac -profile:a aac_he -b:a 4k output.aac && hexdump -v -e '16/1 "0x%x," "\n"' -v output.aac
          return new Uint8Array([
            0x1,
            0x40,
            0x22,
            0x80,
            0xa3,
            0x4e,
            0xe6,
            0x80,
            0xba,
            0x8,
            0x0,
            0x0,
            0x0,
            0x1c,
            0x6,
            0xf1,
            0xc1,
            0xa,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5e,
          ]);
        } else if (channelCount === 2) {
          // ffmpeg -y -f lavfi -i "aevalsrc=0|0:d=0.05" -c:a libfdk_aac -profile:a aac_he_v2 -b:a 4k output.aac && hexdump -v -e '16/1 "0x%x," "\n"' -v output.aac
          return new Uint8Array([
            0x1,
            0x40,
            0x22,
            0x80,
            0xa3,
            0x5e,
            0xe6,
            0x80,
            0xba,
            0x8,
            0x0,
            0x0,
            0x0,
            0x0,
            0x95,
            0x0,
            0x6,
            0xf1,
            0xa1,
            0xa,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5e,
          ]);
        } else if (channelCount === 3) {
          // ffmpeg -y -f lavfi -i "aevalsrc=0|0|0:d=0.05" -c:a libfdk_aac -profile:a aac_he_v2 -b:a 4k output.aac && hexdump -v -e '16/1 "0x%x," "\n"' -v output.aac
          return new Uint8Array([
            0x1,
            0x40,
            0x22,
            0x80,
            0xa3,
            0x5e,
            0xe6,
            0x80,
            0xba,
            0x8,
            0x0,
            0x0,
            0x0,
            0x0,
            0x95,
            0x0,
            0x6,
            0xf1,
            0xa1,
            0xa,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5a,
            0x5e,
          ]);
        }
        break;
    }
    return undefined;
  }
}

export default AAC;
